/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import s from './404.module.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Страница не нашлась" />
    <div className="flex tr-bg-gray-2 py-20 flex-grow mlg:py-16 msm:py-8">
      <div className="container flex-grow flex flex-col items-center justify-center text-center">
        <h1 className={s.title}>Страница не нашлась</h1>
        <div className={s.description}>
          Извините, но данная страница не найдена, если вы не можете найти необходимую вам информацию, можете связаться
          с нами
        </div>
        <div className={s.buttons}>
          <Link to="/" className={cn('button button-normal button-yellow', s.button)}>
            Перейти на главную
          </Link>
          <Link to="/contacts" className={cn('button button-normal button-green', s.button)}>
            Связаться с нами
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
